//Block
.block__search {
  position: absolute;
  top: px-rem(10px);
  right: px-rem(15px);
  z-index: 490;
  display: none;

  &.open {
    display: block;
  }
}

.block__search .content {
  position: relative;
}

.block__search .form-actions {
  width: px-rem(20px);
  height: px-rem(20px);
  position: absolute;
  top: 50%;
  right: px-rem(4px);
  transform: translateY(-50%);
  background: $grey-lightest;

  &:before {
    content: "";
    pointer-events: none;
    @include sprite(magnifier);
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.block__search input[type="submit"] {
  border: none;
  background: transparent;
  text-indent: px-rem(-9999px);
  width: 100%;
  height: 100%;

  &:hover {
    background: transparent;
  }
}

.block__search input[type="text"] {
  padding-right: px-rem(28px);
  border: 1px solid $grey-light;
  background: $grey-lightest;
  line-height: px-rem(26px);
  max-width: px-rem(160px);
}

//Node
.page-search-node #block-system-main > .content {
  padding: px-rem(20px) 0;
}

.page-search-node .search-form{
  max-width: px-rem(500px);
  @include clearfix;
}

.page-search-node .search-form .form-submit{
  margin-top: px-rem(20px);
  float: right;
}

.page-search-node .search-advanced {
  clear: both;
  margin: px-rem(20px) 0;
}

//Autocomplete
.block__search .ui-autocomplete-field-group,
.block__search .ui-autocomplete-field-name,
.block__search .ui-autocomplete-field-comment_count,
.page-search-node .ui-autocomplete-field-group,
.page-search-node .ui-autocomplete-field-name,
.page-search-node .ui-autocomplete-field-comment_count {
  display: none;
}

.block__search .ui-state-focus,
.page-search-node .ui-state-focus {
  background-color: $blue-dark;
  color: $white;
  background-image: none;
  border: 0;
  cursor: pointer;
}

ul[data-sa-theme="basic-green"].ui-menu {
  width: px-rem(290px) !important;
  min-width: px-rem(250px);
}

.page-search-node ul[data-sa-theme="basic-green"].ui-menu {
  width: px-rem(500px) !important;
}

.page-search-node .search-results {
  margin: 0;
  padding: 0;
}

.page-search-node .search-results li {
  padding-bottom: px-rem(10px);
  border-bottom: 1px solid $grey-light;
}

.page-search-node .search-results li .title a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.page-search-node .search-info {
  display: none;
}


//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  //Block
  .block__search {
    top: px-rem(-45px);
    right: px-rem(120px);
    display: block;
  }

  //Node
  .page-search-node #block-system-main > .content {
    padding: px-rem(40px) 0;
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

}
//end break-4