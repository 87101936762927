//teasers
.block__news-teasers {
  margin-bottom: px-rem(10px);
}

.news--teasers .view-content {
  display: flex;
  flex-wrap: wrap;
}

.news--teaser {
  display: flex;
  flex-direction: column;
  margin-bottom: px-rem(20px);
}

.news--teaser .views-field-view-node,
.news--teaser .field-content,
.news--teaser a {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.news__teaser-title {
  background: rgba($grey-lightest, 0.5);
  padding: px-rem(5px) px-rem(10px);
  color: $blue-dark;
  font-weight: 700;
  transition: all 0.25s ease-out;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.news__teaser-date {
  display: block;
  color: rgba($black, 0.5);
  transition: all 0.25s ease-out;
}

.news--teaser a:hover .news__teaser-title {
  color: $white;
  background: $blue-dark;
}

.news--teaser a:hover .news__teaser-date {
  color: $white;
}

//Node
.node-type-news .field-name-field-date {
  color: rgba($black, 0.5);
  font-size: px-rem(18px);
  font-weight: 700;
  margin-bottom: px-rem(20px);
}

.node-type-news .field-name-body p:last-of-type {
  margin-bottom: 0;
}

.node-type-news .field-name-field-afbeelding {
  margin-bottom: px-rem(30px);
}

//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  //Teasers
  .block__news-teasers {
    margin-bottom: px-rem(20px);
  }

  .news--teaser {
    margin-bottom: px-rem(30px);
  }

  //Node
  .node-type-news .field-name-body {
    max-width: 80%;
  }

  .node-type-news .field-name-field-afbeelding {
    margin-bottom: px-rem(50px);
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

}
//end break-4