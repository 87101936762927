//Product teaser
.product--teasers-overview {
  padding-bottom: px-rem(30px);
}

.product--teasers-overview .view-content {
  display: flex;
  flex-wrap: wrap;
}

.product--teaser {
  margin-top: px-em(10px);
  margin-bottom: px-em(10px);
  display: flex;
  flex-direction: column;
}

.product--teaser .views-field-path,
.product--teaser .field-content,
.product--teaser a {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.product__teaser-title {
  background: rgba($grey-lightest, 0.5);
  padding: px-rem(5px) px-rem(10px);
  color: $blue-dark;
  font-weight: 700;
  transition: all 0.25s ease-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.product--teaser a:hover .product__teaser-title {
  color: $white;
  background: $blue-dark;
}

//Product detail page
.node-type-seven-product .node-seven-product h2 {
  color: $green;
  text-transform: none;
  font-weight: 900;
  font-size: px-rem(18px);
}

.node-type-seven-product .field-name-field-icoon-tags {
  margin-bottom: px-rem(50px);
}

.node-type-seven-product .field-name-field-icoon-tags h2 {
  display: none;
}

.node-type-seven-product .paragraph__view-slider {
  padding: 0;
}

.node-type-seven-product .paragraph__product-carousel {
  margin-bottom: px-rem(50px);
}

.node-type-seven-product .flexslider {
  margin: 0;
}

.node-type-seven-product .carousel {
  height: auto;
  background: $blue-dark;
  width: 100%;
  padding-top: px-rem(2px);
}

.node-type-seven-product .carousel li:hover {
  cursor: pointer;
}

//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  //Product teaser
  .product--teasers-overview {
    padding-bottom: px-rem(50px);
  }

  .product--teaser {
    margin-top: px-em(15px);
    margin-bottom: px-em(15px);
  }

  .product__teaser-title {
    padding: px-rem(10px) px-rem(30px);
  }

  .node-type-seven-product .paragraph__product-carousel {
    margin-bottom: px-rem(80px);
  }

  //Product
  .node-type-seven-product .paragraphs-items {
    float: left;
    width: 48%;
  }

  .node-type-seven-product .field-name-body,
  .node-type-seven-product .field-name-field-icoon-tags {
    float: right;
    width: 48%;
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

}
//end break-4


.page-node-183 .field-type-text-with-summary h2,
.page-node-184 .field-type-text-with-summary h2,
.page-node-185 .field-type-text-with-summary h2,
.page-node-180 .field-type-text-with-summary h2,
.page-node-181 .field-type-text-with-summary h2,
.page-node-182 .field-type-text-with-summary h2{
  color:#aab151;
  font-size: 32px!important;
}




