//Language switcher
.block__language-switcher {
  float: right;
  margin-right: px-rem(50px);
}

.block__language-switcher .content ul {
  @include reset-list;
}

.block__language-switcher .content ul li {
  display: inline-block;
}

.block__language-switcher a.language-link {
  display: block;
  width: px-rem(30px);
  line-height: px-rem(30px);
  text-align: center;
  font-weight: 700;
}

.block__language-switcher a.language-link:hover,
.block__language-switcher a.language-link.active {
  background: $blue-dark;
  color: $white;
}

//Social icons
.block__social-icons {
  position: absolute;
  top: px-rem(10px);
  left: px-rem(15px);
  z-index: 490;
  display: none;

  &.open {
    display: block;
  }
}

.block__social-icons .content ul {
  @include reset-list;
}

.block__social-icons .content ul li {
  float: left;
  margin-right: px-rem(10px);
}

.block__social-icons .content ul li a {
  display: block;
  text-indent: px-rem(-9999px);
  position: relative;
  width: px-rem(30px);
  height: px-rem(30px);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-out;
  }

  &:hover:before {
    opacity: 0.75;
  }
}

.block__social-icons ul li a.facebook {

  &:before {
    @include sprite(facebook);
  }
}

.block__social-icons ul li a.youtube {

  &:before {
    @include sprite(youtube);
  }
}


//CTA green
.block__cta-green {
  background: $green;
  float: left;
  width: 100%;
  padding: px-rem(26px) 0 px-rem(30px);
  color: $white;
  @include break-out(15px);
  @include clearfix;
}

.block__cta-green h2 {
  color: $white;
  font-weight: 700;
  margin: 0 0 px-rem(10px);

  &:after {
    display: none;
  }
}

.block__cta-green .content p {
  font-size: px-rem(19px);

  &:last-of-type {
    margin: 0;
  }
}

.block__cta-green .btn {
  margin-top: px-rem(10px);
}

//Block info
.block__info {
  margin-bottom: px-rem(30px);
}

.block__info .content a {
  position: relative;
  font-size: px-rem(20px);
  padding-left: px-rem(95px);
  text-transform: uppercase;
  color: $blue-dark;
  display: table-cell;
  height: px-rem(80px);
  vertical-align: middle;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: px-rem(80px);
    height: px-rem(80px);
    transition: all 0.25s ease-out;
  }

  &:hover {
    color: $green;
  }

  &:hover:before {
    opacity: 0.75;
  }
}

.block__info-technieken .content a {
  &:before {
    background: url('../images/technieken.png') no-repeat;
    background-size: px-rem(80px) px-rem(80px);
  }
}

.block__info-toebehoren .content a {
  &:before {
    background: url('../images/toebehoren.png') no-repeat;
    background-size: px-rem(80px) px-rem(80px);
  }
}

.block__info-brochure .content a {
  &:before {
    background: url('../images/brochure.png') no-repeat;
    background-size: px-rem(80px) px-rem(80px);
  }
}

//Contact
.block__contact-info {
  margin-bottom: px-rem(30px);
}

//Contact form
.block__contact-form {
  margin-bottom: px-rem(30px);
}

.block__contact-form .form-actions {
  float: right;
  margin-bottom: px-rem(30px);
}

//Google map
.block__google-map .content > div {
  line-height: 0;
}

.block__google-map iframe {
  height: px-rem(380px);
}


//Footer blocks
.block__footer {
  color: $white;
}

.block__footer a {
  color: $white;

  &:hover {
    color: $green;
  }
}

.block__footer h2 {
  color: $white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 px-rem(20px);
}

//Footer links
.block__footer-links ul.menu {
  @include reset-list;
}

.block__footer-links ul.menu li {
  padding-left: px-rem(15px);
  position: relative;

  &:before {
    content: '';
    width: px-rem(4px);
    height: px-rem(6px);
    position: absolute;
    top: px-rem(10px);
    left: 0;
    background: url('../images/list-item-icon-white.png') no-repeat;
  }
}

.block__footer-links ul.menu ul.menu{
  display: none;
}

.block__footer-links a.active {
  color: $green;
}

//Footer social media
.block__social-media .content ul {
  @include reset-list;
}

.block__social-media .content ul li {
  display: block;
  min-height: px-rem(30px);
  margin-bottom: px-rem(15px);
}

.block__social-media .content ul li a {
  display: block;
  position: relative;
  padding-left: px-rem(40px);

  &:before {
    content: '';
    width: px-rem(30px);
    height: px-rem(30px);
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-out;
  }

  &:hover:before {
    opacity: 0.75;
  }
}

.block__social-media ul li a.facebook {

  &:before {
    @include sprite(facebook);
  }
}

.block__social-media ul li a.youtube {

  &:before {
    @include sprite(youtube);
  }
}


//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

  //language switcher
  .block__language-switcher {
    position: relative;
    top: px-rem(30px);
    transform: translateY(-50%);
  }

  //CTA green
  .block__cta-green {
    @include break-out(200%);
  }

  //Block info
  .block__info {
    float: left;
    margin-right: px-rem(50px);
  }

  //Contact
  .block__contact-info {
    float: right;
    width: 38%;
    text-align: right;
  }

  //Contact form
  .block__contact-form {
    float: left;
    width: 58%;
  }
}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  //language switcher
  .block__language-switcher {
    margin-right: 0;
  }

  //Social icons
  .block__social-icons {
    display: block;
    top: px-rem(-45px);
    left: auto;
    right: px-rem(300px);
  }

  //CTA green
  .block__cta-green {
    padding-top: px-rem(46px);
    padding-bottom: px-rem(50px);
  }

  .block__cta-green .content {
    position: relative;
    @include clearfix;
  }

  .block__cta-green .content h2,
  .block__cta-green .content p,
  .block__cta-green .content ul {
    float: left;
    max-width: 70%;
  }

  .block__cta-green .btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  //Block info
  .block__info {
    margin: 0 px-rem(80px) px-rem(50px) 0;
  }

  //Footer copyright
  .block__footer-copyright {
    text-align: right;
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

  //Block info
  .block__info {
    margin-right: px-rem(125px);
  }
}
//end break-4
