.block__banner {
  width: 100%;
  position: relative;
}

.block__banner h1 {
  display: inline-block;
  color: $white;
  background: $green;
  padding: px-rem(10px) px-rem(20px);
  margin: 0;
  font-weight: 900;
  text-transform: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .front & {
    display: none;
  }
}


.block__banner .adaptive-background-image {
  background-size: cover !important;
  min-height: px-rem(200px) !important;

  .front & {
    min-height: auto !important;
    height: 75vh;
  }
}



//break-1
@media (min-width: $screen-xs-min) {

  .block__banner .adaptive-background-image {
    min-height: px-rem(250px) !important;
  }
}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

  .block__banner .adaptive-background-image {
    min-height: px-rem(300px) !important;
    
    .front & {
      height: 60vh;
    }
  }
}
//end break-2

//break-3
@media (min-width: $screen-md-min) {


}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

  .block__banner .adaptive-background-image {
    min-height: px-rem(350px) !important;

    .front & {
      height: 75vh;
    }
  }
}
//end break-4