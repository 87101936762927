/* general */
$ieSprite: '.lt-ie9' !default;
$sprite: map-get($icons, sprite) !default;

/* Colors */
$white: #ffffff;
$black: #000000;
$green: #aab151;
$green-dark: #919745;
$blue-dark: #222641;
$grey-light: #c6c7ce;
$grey-lightest: #ebeff0;


/* Theme color settings */
$base-color: $black;
$primary-color: $black;
$secondary-color: $white;

$base-font-color: $blue-dark;

$link-color: $black;
$link-hover-color: $black;

$btn-bg-color: $blue-dark;
$btn-font-color: $white;
$btn-hover-bg-color: $white;
$btn-hover-font-color: $blue-dark;

$btn-alt-bg-color: $white;
$btn-alt-font-color: $green;
$btn-alt-hover-bg-color: $green;
$btn-alt-hover-font-color: $white;


/* fonts */
$base-font-size: 16; //Aanpassen naar standaard font grootte in px
$base-line-height: 24; //Aanpassen naar standaard line-height in px

$lato: 'Lato', sans-serif;
$arial: 'Arial', sans-serif;

$base-font: $arial;
$primary-font: $arial;
$secondary-font: $arial;


/* Breakpoints (same as default bootstrap, mobile first) */
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;


/* Calculated variables (Do not change) */
$em-base: 16;
$relative-base-font-size: px-em($base-font-size, 16);
$relative-base-line-height: px-em($base-line-height, $base-font-size);


/* breakpoints */
$show-breakpoints: false;


html {

  &:before {
    content: "Default";
    background: red;
    opacity: 0.85;
    width: auto;
    color: black;
    position: fixed;
    top: 100px;
    right: 0;
    padding: 5px 10px;
    z-index: 9999;
    font-size: 12px;

    @if $show-breakpoints {
      display: block;
    } @else {
      display: none;
    }
  }
}

//break-1
@media (min-width: $screen-xs-min) {

  html:before {
    content: "Break-1";
    background: orange;
  }
}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

  html:before {
    content: "Break-2";
    background: orange;
  }
}
//end break-2


//break-3
@media (min-width: $screen-md-min) {
  html:before {
    content: "Break-3";
    background: yellow;
  }
}
//end break-3


//break-4
@media (min-width: $screen-lg-min) {
  html:before {
    content: "Break-4";
    background: greenyellow;
  }
}
//end break-4