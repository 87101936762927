//CTA
.paragraph__cta {
  width: 100%;
  padding: px-rem(26px) 0 px-rem(30px);
  @include break-out(200%);
  color: $white;
}

.paragraph__cta-blue {
  background: $blue-dark;
}

.paragraph__cta-green {
  background: $green;
}

.paragraph__cta h1,
.paragraph__cta h2 {
  color: $white;
  font-weight: 700;
  margin: 0 0 px-rem(10px);

  &:after {
    display: none;
  }
}

.paragraph__cta p:last-of-type {
  margin: 0;
}

.paragraph__cta .field-name-field-cta-text,
.paragraph__cta .field-name-field-cta-text-green {
  float: left;
  width: 100%;
  font-size: px-rem(19px);
  margin-bottom: px-rem(20px);
}

.paragraph__cta .field-name-field-cta-link,
.paragraph__cta .field-name-field-cta-link-green {
  float: left;
  width: 100%;
}

.paragraph__cta .field-name-field-cta-link a,
.paragraph__cta .field-name-field-cta-link-green a {
  display: inline-block;
  background: $white;
  font-size: px-rem(18px);
  padding: px-rem(8px) px-rem(16px);
  border: px-rem(1px) solid $white;
  border-radius: px-rem(2px);
  text-transform: uppercase;
  transition: all 0.25s ease-out;

  &:hover {
    background: transparent;
    color: $white;
  }
}

.paragraph__cta-blue .field-name-field-cta-link a {
  color: $blue-dark;
}

.paragraph__cta-green .field-name-field-cta-link-green a {
  color: $green;
}

//CTA img left text right
.paragraph__cta-img-left-text-right {
  width: 100%;
}

.paragraph__cta-img-left-text-right .adaptive-background-image {
  @include break-out(15px);
  min-height: px-rem(300px) !important;
}

.paragraph__cta-img-left-text-right .cta__text-right {
  background: $green;
  padding: px-rem(30px) 0;
  color: $white;
  text-align: center;
  @include break-out(15px);
}

.paragraph__cta-img-left-text-right .cta__text-right h2 {
  color: $white;

  &:after {
    background: $white;
  }
}

.paragraph__cta-img-left-text-right .field-name-field-cta-link-btn a {
  display: inline-block;
  background: $white;
  color: $green;
  font-size: px-rem(18px);
  padding: px-rem(8px) px-rem(16px);
  margin-top: px-rem(25px);
  border: px-rem(1px) solid $white;
  border-radius: px-rem(2px);
  text-transform: uppercase;
  transition: all 0.25s ease-out;

  &:hover {
    background: transparent;
    color: $white;
  }
}

//View
.paragraph__view,
.paragraph__view-slider {
  padding-top: px-rem(30px);
  padding-bottom: px-rem(20px);
  text-align: center;
}

.paragraph__view .field-name-field-text p:last-of-type,
.paragraph__view-slider .field-name-field-text p:last-of-type {
  margin: 0;
}

.paragraph__view .field-name-field-view {
  margin-top: px-rem(25px);
}

//Offers
.paragraph__offers .field-name-field-view .view-content {
  display: flex;
  flex-wrap: wrap;
}

.paragraph__view .offer {
  margin-top: px-em(10px);
  margin-bottom: px-em(10px);
  display: flex;
  flex-direction: column;
}

.paragraph__view .offer .views-field-field-link,
.paragraph__view .offer .field-content,
.paragraph__view .offer a {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.paragraph__view .offer__title {
  background: rgba($grey-lightest, 0.5);
  padding: px-rem(5px) px-rem(10px);
  color: $blue-dark;
  font-weight: 700;
  transition: all 0.25s ease-out;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.paragraph__view .offer a:hover .offer__title {
  color: $white;
  background: $blue-dark;
}

//Testimonials
.paragraph__testimonials {
  position: relative;
  color: $white;

  &:before {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50vw);
  }

  &:before {
    background: url('../images/bg_testimonials.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.paragraph__testimonials h2 {
  color: $white;

  &:after {
    background: $white;
  }
}

.paragraph__testimonials .field-name-field-view-slider {
  position: relative;
  padding-top: px-rem(20px);
}

.paragraph__testimonials .views-field-field-image-person {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: px-rem(15px);
}

//usps
.paragraph__usps {
  background: $green;
  color: $white;
  @include break-out(200%);
}

.paragraph__usps .field-name-field-view {
  margin-top: 0;
}

.paragraph__usps .views-field-field-icon {
  display: inline-block;
  margin-bottom: px-rem(5px);
}

.paragraph__usps .views-field-title {
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph__usps .usp {
  margin: px-rem(15px) 0;
}

//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

  //CTA img left text right
  .paragraph__cta-img-left-text-right {
    display: table;
  }

  .paragraph__cta-img-left-text-right .content {
    display: table-row;
  }

  .paragraph__cta-img-left-text-right .field-name-field-cta-image {
      display: table-cell;
      position: relative;
      left: 50%;
      transform: translateX(-50vw);
      width: 50vw;
  }

  .paragraph__cta-img-left-text-right .adaptive-background-image {
    @include break-out(0);
    width: 50vw !important;
    min-height: 100% !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  .paragraph__cta-img-left-text-right .cta__text-right {
    display: table-cell;
    width: 50%;
    padding: px-em(50px) 0 px-em(50px) px-em(25px);
    position: relative;

    &:after {
      content: '';
      background: $green;
      position: absolute;
      top: 0;
      left: 100%;
      width: 500%;
      height: 100%;
    }
  }

  //Testimonials
  .paragraph__testimonials .views-field-body {
    padding: 0 px-rem(50px);
  }
}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  //CTA
  .paragraph__cta {
    padding-top: px-rem(46px);
    padding-bottom: px-rem(50px);
  }

  .paragraph__cta .content {
    position: relative;
  }

  .paragraph__cta .field-name-field-cta-text,
  .paragraph__cta .field-name-field-cta-text-green {
    width: 70%;
    margin-bottom: 0;
  }

  .paragraph__cta .field-name-field-cta-link,
  .paragraph__cta .field-name-field-cta-link-green {
    text-align: right;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  //view
  .paragraph__view,
  .paragraph__view-slider {
    padding-top: px-rem(50px);
    padding-bottom: px-rem(35px);
  }

  //Offers
  .paragraph__view .offer {
    margin-top: px-em(15px);
    margin-bottom: px-em(15px);
  }

  .paragraph__view .offer__title {
    padding: px-rem(10px) px-rem(30px);
  }

  //Testimonials
  .paragraph__testimonials .views-field-body {
    padding: 0 px-rem(100px);
  }

  //Paragraphs
  .paragraph__usps {
    padding-top: px-rem(70px);
    padding-bottom: px-rem(70px);
  }

  .paragraph__usps .usp {
    margin: 0;
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

  //CTA img left text right
  .paragraph__cta-img-left-text-right .cta__text-right {
    padding: px-em(50px) 0 px-em(50px) px-em(50px);
  }

  //Testimonials
  .paragraph__testimonials .views-field-body {
    padding: 0 px-rem(250px);
  }
}
//end break-4