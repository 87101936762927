@mixin reset-list() {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}


@mixin break-out($width) {
  box-sizing: content-box;
  margin-left: -$width;
  padding-left: $width;
  margin-right: -$width;
  padding-right: $width;
}


/* Sprites */

/// Set the `$sprite` map
/// @group sprite
$sprite: map-get($icons, sprite) !default;

/// Retrive an attributes value for a specific icon in the sprite map
/// @param {string} $icon - The name of the icon
/// @param {string} $attr - The attribute you wish to retrieve (e.g. width)
@function sprite-attr($icon, $attr) {
  $newIcon: map-get($icons, $icon);
  @if $newIcon == null {
    @warn "Can't find an icon with the name #{$icon}";
  }
  @return map-get($newIcon, $attr);
}

/// Create a map with the specified icon of attributes
/// @param {string} $icon - The name of the icon
@function icon-attr($icon) {
  $attr: (
          width: sprite-attr($icon, width),
          height: sprite-attr($icon, height),
          x: sprite-attr($icon, backgroundX),
          y: sprite-attr($icon, backgroundY)
  );

  @return $attr;
}

@mixin ie-sprite($icon, $type: all) {
  $iconMap: icon-attr($icon);

  @if $ieSprite {
    #{$ieSprite} & {
      @if $type == all {
        // Shares the PNG background
        @include ie-sprite-css;
      }
      // Outputs dimensions of icon
      @if $type == all or $type == size {
        width: map-get($iconMap, width);
        height: map-get($iconMap, height);
      }
      // Outputs background position
      @if $type == all or $type == bg {
        background-position: (map-get($iconMap, x) - 5) (map-get($iconMap, y) - 5);
      }
    }
  }
}

@mixin sprite($icon, $type: all) {
  @if $type == all {
    // Shares the backgrounds
    @include sprite-css;
  }

  $iconMap: icon-attr($icon);

  // Outputs dimensions
  @if $type == all or $type == size {
    width: map-get($iconMap, width) + 1;
    height: map-get($iconMap, height) + 1;
  }

  // Outputs background position
  @if $type == all or $type == bg {
    background-position: map-get($iconMap, x) - 5 map-get($iconMap, y) - 5;
  }

  // Add ie fallback
  @include ie-sprite($icon, $type);

}


/// Assign the correct SVG background image and dimensions to the element
@mixin sprite-css() {
  display: inline-block;
  background-image: url(map-get($sprite, svgPath));
  background-size: map-get($sprite, width) map-get($sprite, height);
}
/// Assign the correct PNG background image and dimensions to the element
@mixin ie-sprite-css() {
  background-image: url(map-get($sprite, pngPath));
}