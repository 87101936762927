// Main menu
.block__main-menu {
  float: right;
  position: relative;
  z-index: 480;
  width: 100%;
  margin-top: px-rem(-27px);
  transition: all 0.25s ease-out;
}

.block__main-menu h2 {
  @include sprite(btn-menu);
  position: absolute;
  z-index: 500;
  top: 0;
  right: 0;
  margin: 0;
  text-indent: -9999px;

  &:after {
    display: none;
  }
}

.block__main-menu.open h2 {
  @include sprite(btn-close);
  top: -3px;
  right: 3px;
}

.block__main-menu .content {
  background: $white;
  position: absolute;
  top: px-rem(27px);
  left: 0;
  width: 100%;
  height: calc(100vh - 72px);
  padding: px-rem(50px) 0 px-rem(10px);
  overflow-y: scroll;
  overflow-x: hidden;
  display: none;
  @include break-out(100%);
  transition: all 0.25s ease-out;
}

.block__main-menu.open .content {
  display: block;
}

.block__main-menu .menu-wrapper {
  position: relative;
  padding-bottom: px-rem(50px);
  background: $white;
  @include break-out(100%);
}

.block__main-menu ul.menu {
  @include reset-list();
}

.block__main-menu ul.menu li a {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  font-size: px-rem(18px);
  line-height: px-rem(45px);
  font-weight: 400;
  border-bottom: px-rem(1px) solid $grey-light;
  @include break-out(100%);
  transition: all 0.25s ease-out;

  &:hover {
    color: $white;
    background: $green;
    text-decoration: none;
  }
}

.block__main-menu .menu-wrapper > ul.menu > li > a {
  &.active {
    color: $white;
    background: $green;
    text-decoration: none;
  }
}

.block__main-menu ul.menu ul.menu li {
  position: relative;

  &:before {
    content: '';
    @include sprite(arrow-right-narrow-small-black);
    position: absolute;
    top: 50%;
    left: px-rem(10px);
    transform: translateY(-50%);
  }

  &:hover:before {
    @include sprite(arrow-right-narrow-small-white);
  }
}

.block__main-menu .menu-wrapper > ul.menu > li ul.menu li a {
  text-transform: none;
  font-size: px-rem(17px);
  line-height: px-rem(45px);
  border-bottom: px-rem(1px) solid $grey-light;
}

.block__main-menu .menu-wrapper > ul.menu > li:last-of-type > a {
  border-bottom: 0;
}

.block__main-menu ul.menu li > ul a {
  padding-left: calc(100% + 25px);
}


//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

  // Main menu
  .block__main-menu {
    margin-top: px-rem(-41px);
  }

  .block__main-menu .content {
    top: px-rem(41px);
  }
}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  //Main menu
  .block__main-menu {
    width: 100%;
    margin: 0;
    transition: all 0.25s ease-out;
  }

  .block__main-menu h2,
  .block__main-menu.open h2{
    display: none;
  }

  .block__main-menu .menu-wrapper {
    background: $green;
    padding-top: 0;
    padding-bottom: 0;
    @include break-out(200%);
  }

  .block__main-menu .content {
    display: block;
    background: transparent;
    position: relative;
    top: 0;
    height: auto;
    @include break-out(0);
    padding: 0;
    box-sizing: border-box;
    overflow: visible;
  }

  .block__main-menu ul.menu {
    text-align: right;
    line-height: 0;
  }

  .block__main-menu ul.menu li {
    display: inline-block;
    position: relative;
    margin-right: px-rem(-4px);
  }

  .block__main-menu ul.menu li a {
    box-sizing: border-box;
    border: 0;
    font-size: px-rem(17px);
    line-height: px-rem(40px);
    padding: 0 px-rem(14px);
    margin: 0;
    color: $white;
    position: relative;
    overflow: hidden;
    text-transform: none;

    &:hover,
    &.active-trail {
      background: $green-dark;
    }
  }

  .block__main-menu .menu-wrapper > ul.menu > li > a {
    &.active {
      background: $green-dark;
    }
  }

  .block__main-menu .menu-wrapper > ul.menu > li ul.menu {
    display: none;
    position: absolute;
    top: px-rem(40px);
    left: 0;
    transition: all 0.25s ease-out;
  }

  .block__main-menu .menu-wrapper > ul.menu > li:hover > ul.menu {
    display: block;
  }

  .block__main-menu .menu-wrapper > ul.menu > li > ul.menu li {
    display: block;
    width: 100%;
    position: relative;
  }

  .block__main-menu .menu-wrapper > ul.menu > li ul.menu li a {
    background: rgba($green, 0.9);
    border-bottom: 1px solid $white;
    text-transform: none;
    font-size: px-em(17px);
    line-height: px-em(40px);
    padding: 0 px-em(15px);
    white-space: nowrap;
    text-align: left;

    &:hover,
    &.active-trail, {
      background: $green-dark;
      color: $white;
    }
  }

  .block__main-menu .menu-wrapper > ul.menu > li ul.menu li:first-of-type a {
    border-top: px-rem(1px) solid $white;
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

  //Main menu
  .block__main-menu ul.menu li a {
    padding: 0 px-rem(15px);
  }
}
//end break-4