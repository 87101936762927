header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 499;
  background: $white;
}

#logo {
  display: block;
  float: left;
  @include break-out(100%);
  margin-right: 0;
  padding-right: 0;
  background: $blue-dark;
}

#logo img {
  height: px-rem(30px) !important;
}

#content {
  padding: px-rem(30px) 0 0;

  .front & {
    padding: 0;
  }
}

.field-name-body {
  padding-bottom: px-rem(30px);
}

footer {
  background: $blue-dark;
  padding: px-rem(30px) 0 0;
}

.footer__first-column,
.footer__second-column {
  margin-bottom: px-rem(25px);
}

.footer__third-column {
  clear: left;
}

#footer-bottom {
  padding: px-rem(30px) 0 px-rem(15px);
}

//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

  #logo img {
    height: px-rem(60px) !important;
  }

}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  #content {
    padding: px-rem(50px) 0 0;
  }

  .field-name-body {
    padding-bottom: px-rem(50px);
  }

  footer {
    padding: px-rem(50px) 0 0;
  }

  .footer__first-column,
  .footer__second-column {
    margin-bottom: 0;
  }

  .footer__third-column {
    clear: none;
  }

  #footer-bottom {
    padding: px-rem(50px) 0 px-rem(25px);
  }

  .page-node-46 .node-seven-product .field-type-text-with-summary{
    float: none;
    width: 100%;
  }
  .page-node-46 .node-seven-product .paragraphs-items{
    float: none;
  }

  .page-node-50 .node-seven-product .field-type-text-with-summary{
    float: none;
    width: 100%;
  }
  .page-node-50 .node-seven-product .paragraphs-items{
    float: none;
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

}
//end break-4
