//this class is added to the body on page load, to avoid transitions on page load
.preload * {
  transition: none !important;
}

html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

html {
  font-size: $relative-base-font-size;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  position: relative;
}

body {
  font-size: 100%;
  font-family: $lato;
  line-height: $relative-base-line-height;
  color: $base-font-color;
  padding-top: px-rem(30px);
}

html body.admin-menu {
  padding-top: px-rem(32px);
}

h1,
h2 {
  display: inline-block;
  font-size: 26px;
  margin: 0 0 px-rem(15px);
  text-transform: uppercase;
  color: $blue-dark;

  .front & {
    position: relative;
    margin: 0 0 px-rem(30px);

    &:after {
      content: '';
      width: px-rem(40px);
      height: px-rem(2px);
      background: $blue-dark;
      position: absolute;
      bottom: px-rem(-16px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

h3 {
  font-weight: 700;
}

p {
  margin: 0 0 px-rem(15px);

  &:empty {
    margin: 0 0 0;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto !important;
}

.content p > img {
  display: inline-block;
}

a {
  color: $link-color;
  transition: all 0.25s ease-out;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

.field-name-body ul {
  .block-system & {
      @include reset-list;
  }
}

.field-name-body ul li {
  .block-system & {
    padding-left: px-rem(15px);
    position: relative;

    &:before {
      content: '';
      width: px-rem(4px);
      height: px-rem(6px);
      position: absolute;
      top: px-rem(10px);
      left: 0;
      background: url('../images/list-item-icon-blue-dark.png') no-repeat;
    }
  }
}


//Buttons
.btn {
  white-space: normal;
  font-size: px-rem(18px);
  padding: px-rem(8px) px-rem(16px);
  border: px-rem(1px) solid $btn-bg-color;
  border-radius: px-rem(2px);
  text-transform: uppercase;
  transition: all 0.25s ease-out;
}

.btn-default,
.btn-success {
  background-color: $btn-bg-color;
  border-color: $btn-bg-color;
  color: $btn-font-color;

  &:hover {
    background-color: $btn-hover-bg-color;
    border-color: $btn-hover-bg-color;
    color: $btn-hover-font-color;
  }
}

.btn-alternate {
  background-color: $btn-alt-bg-color;
  color: $btn-alt-font-color;
  border: 1px solid $btn-alt-bg-color;

  &:hover {
    background-color: $btn-alt-hover-bg-color;
    color: $btn-alt-hover-font-color;
  }
}

//Bootstrap
.view-bootstrap-view{
  margin-left: -15px;
  margin-right: -15px;
}

.row-offcanvas {
  position: relative;
  -webkit-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out;
}

.row-offcanvas-right {
  right: 0;
}

.row-offcanvas-left {
  left: 0;
}

.row-offcanvas-right
.sidebar-offcanvas {
  right: -50%; /* 6 columns */
}

.row-offcanvas-left
.sidebar-offcanvas {
  left: -50%; /* 6 columns */
}

.row-offcanvas-right.active {
  right: 50%; /* 6 columns */
}

.row-offcanvas-left.active {
  left: 50%; /* 6 columns */
}

.sidebar-offcanvas {
  position: absolute;
  top: 0;
  width: 50%; /* 6 columns */
}


//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

  body {
    padding-top: px-rem(60px);
  }


  h1,
  h2 {
    font-size: 36px;
    margin: 0 0 px-rem(25px);

    .front & {
      margin: 0 0 px-rem(50px);

      &:after {
        bottom: px-rem(-26px);
      }
    }


  }
}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

  html body.admin-menu {
    padding-top: px-rem(71px);
  }
}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

}
//end break-4

