label {
  width: 100%;
}

input[type="text"],
input[type="email"],
textarea {
  border: 1px solid $grey-light;
  background: $grey-lightest;
  line-height: px-rem(26px);
  width: 100%;
}

input[type="submit"] {
  background: $btn-bg-color;
  color: $btn-font-color;
  border: 1px solid $btn-bg-color;
  border-radius: 2px;
  font-size: px-rem(18px);
  padding: px-rem(8px) px-rem(16px);
  text-transform: uppercase;
  transition: all 0.25s ease-out;

  &:hover {
    background: $btn-hover-bg-color;
    color: $btn-hover-font-color;
  }
}

.form-item,
.form-actions {
  margin-top: 0;
}

//break-1
@media (min-width: $screen-xs-min) {

}
//end break-1

//break-2
@media (min-width: $screen-sm-min) {

}
//end break-2

//break-3
@media (min-width: $screen-md-min) {

}
//end break-3

//break-4
@media (min-width: $screen-lg-min) {

}
//end break-4